<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  page: {
    title: "Otel Güncelleme",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Otel Güncelleme",
      items: [
        {
          text: "RTS",
          href: "/",
        },
        {
          text: "Otel Yönetimi",
          href: "/hotel",
        },
        {
          text: "Otel Güncelleme",
          active: true,
        },
      ],
      values: {
        brand_name: "",
        hotel_phone: "",
        hotel_email: "",
        hotel_address: "",
        company_name: "",
        company_tax: "",
        company_tax_number: "",
        official_name: "",
        official_phone: "",
        isActive: "",
        rooms: "",
      },
      rooms: [],
      selectedRooms: [],
      isAlertVisibleSuccess: false,
      isAlertVisibleDanger: false,
      isButton: false,
    };
  },
  created() {
    this.getAllRooms();
    this.getData();
  },
  methods: {
    getData() {
      const hotelId = this.$route.params.id;
      const api_url = process.env.VUE_APP_BASEURL + `/hotel/${hotelId}`;
      axios
        .get(api_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.values = response.data?.data;
          const parsedData = JSON.parse(response.data?.data.rooms);
          this.selectedRooms = parsedData;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onSubmit() {
      this.isButton = true;
      const hotelId = this.$route.params.id;
      const api_url = process.env.VUE_APP_BASEURL + `/hotel/${hotelId}`;
      const data = {
        brand_name: this.values.brand_name,
        hotel_phone: this.values.hotel_phone,
        hotel_email: this.values.hotel_email,
        hotel_address: this.values.hotel_address,
        company_name: this.values.company_name,
        company_tax: this.values.company_tax,
        company_tax_number: this.values.company_tax_number,
        official_name: this.values.official_name,
        official_phone: this.values.official_phone,
        rooms: JSON.stringify(this.selectedRooms),
      };
      axios
        .put(api_url, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.showAlertSuccess();
        })
        .catch(() => {
          this.showAlertDanger();
        })
        .finally(() => {
          this.isButton = false;
        });
    },
    getAllRooms() {
      const api_url = process.env.VUE_APP_BASEURL + "/room/getAll";
      axios
        .get(api_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.rooms = response.data?.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    showAlertSuccess() {
      this.isAlertVisibleSuccess = true;
    },
    showAlertDanger() {
      this.isAlertVisibleDanger = true;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <b-alert v-if="isAlertVisibleSuccess" show variant="success">Başarıyla güncellendi.</b-alert>
        <b-alert v-if="isAlertVisibleDanger" show variant="danger">Hata! Lütfen daha sonra tekrar deneyiniz.</b-alert>
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="onSubmit">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="brand_name">Marka Adı:</label>
                    <input v-model="values.brand_name" type="text" class="form-control" id="brand_name" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="hotel_phone">Otel Numarası:</label>
                    <input v-model="values.hotel_phone" type="text" class="form-control" id="hotel_phone" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="hotel_email">Otel Mail:</label>
                    <input v-model="values.hotel_email" type="text" class="form-control" id="hotel_email" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="hotel_address">Otel Adres:</label>
                    <input v-model="values.hotel_address" type="text" class="form-control" id="hotel_address" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="company_name">Şirket Adı:</label>
                    <input v-model="values.company_name" type="text" class="form-control" id="company_name" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="company_tax">Şirket Vergisi:</label>
                    <input v-model="values.company_tax" type="text" class="form-control" id="company_tax" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="company_tax_number">Şirket Vergi Numarası:</label>
                    <input v-model="values.company_tax_number" type="text" class="form-control" id="company_tax_number" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="official_name">Resmi İsim:</label>
                    <input v-model="values.official_name" type="text" class="form-control" id="official_name" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="official_phone">Resmi Numara:</label>
                    <input v-model="values.official_phone" type="text" class="form-control" id="official_phone" />
                  </div>
                </div>
              </div>
            </form>
            <div class="row">
              <div class="col-12 col-sm-6">
                <h5>Otel Odaları</h5>
                <br />
                <div v-for="item in rooms" :key="item.id" id="rooms">
                  <template v-if="item.room_type.value === 1">
                    <input type="checkbox" :id="item.id" v-model="selectedRooms" :value="item.id" />
                    <label :for="item.id">{{ item.room_name }}</label>
                  </template>
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <h5>Rezidans</h5>
                <br />
                <div v-for="item in rooms" :key="item.id" id="rooms">
                  <template v-if="item.room_type.value === 2">
                    <input type="checkbox" :id="item.id" v-model="selectedRooms" :value="item.id" />
                    <label :for="item.id">{{ item.room_name }}</label>
                  </template>
                </div>
              </div>
              <div class="col-12 col-md-12">
                <div class="d-flex justify-content-end align-items-center">
                  <button @click="onSubmit" class="btn btn-primary" :disabled="isButton">Güncelle</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped lang="scss">
#rooms {
  input {
    margin-right: 10px;
  }
}
</style>
